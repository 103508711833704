var pagination = $('.pagination'),
    pagination_previous = pagination.find('.previous'),
    pagination_next = pagination.find('.next'),
    item_container = $('project_items'),
    items = $('.project_items').find('figure'),
    item_count = items.length;

pagination_previous.on('click', function() {
  $.each(items, function() {
    var count = $(this).attr('data-i'),
        count_new = count * 1 + 1;
        count_new = ( count_new > item_count ) ? 1 : count_new;
    $(this).attr('data-i',count_new);
  })
});

pagination_next.on('click', function() {
  $.each(items, function() {
    var count = $(this).attr('data-i'),
        count_new = count * 1 - 1;
        count_new = ( count_new == 0 ) ? item_count : count_new;
    $(this).attr('data-i',count_new);
  })
});
