var target = $('.wp-block-cover .wp-block-button__link'),
    target_position_original_y = target.offset().top,
    stick_class = 'stick';

$(window).on('scroll', function() {
  var scroll_current = $(window).scrollTop();
  if( ! target.hasClass(stick_class) && scroll_current > target_position_original_y ) {
    target.addClass(stick_class);
  } else if( target.hasClass(stick_class) && scroll_current < target_position_original_y ) {
    target.removeClass(stick_class);
  }
});
