$('a[href="#contact"],.action_close,.shroud').on('click', function() {
  var modal_layer = $('.modal');
  modal_layer.attr('data-modal-active', modal_layer.attr('data-modal-active') == '1' ? '0' : '1');
  return false;
});

document.addEventListener( 'wpcf7mailsent', function( event ) {
  setTimeout( function() {
    $('.modal').attr('data-modal-active', '0');
  }, 2000);
}, false );
